<script>
  const token = sessionStorage.getItem("access_token");
  const timestamp = sessionStorage.getItem("access_token_expires");
  if (token === null || timestamp === null) {
    window.history.replaceState(null, "", "/");
  } else if (timestamp < Date.now()) {
    window.history.replaceState(null, "", "/");
    sessionStorage.clear();
  }

  let totalResults;
  let channels = [];
  let includeChannel = [];

  function includeAllChannels() {
    includeChannel = includeChannel.map(() => true);
  }
  function excludeAllChannels() {
    includeChannel = includeChannel.map(() => false);
  }

  // TODO error handling/rate limiting
  async function fetchSubscribedChannels(accessToken) {
    let nextPageToken;
    do {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/subscriptions?part=snippet&mine=true&maxResults=50&order=alphabetical` +
          (nextPageToken ? `&pageToken=${nextPageToken}` : ""),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const body = await response.json();
      nextPageToken = body.nextPageToken;
      totalResults = body.pageInfo.totalResults;
      channels = channels.concat(body.items);
      includeChannel = includeChannel.concat(body.items.map(() => true));
    } while (nextPageToken !== undefined);
  }

  function generateOpmlFile() {
    let opml = `<opml version="1.1">
    <body>
        <outline text="YouTube" title="YouTube">`;
    for (let i in includeChannel) {
      if (includeChannel[i]) {
        opml += `
            <outline text="${channels[i].snippet.title}" title="${channels[i].snippet.title}" type="rss" xmlUrl="https://www.youtube.com/feeds/videos.xml?channel_id=${channels[i].snippet.resourceId.channelId}" />`;
      }
    }
    opml += `
        </outline>
    </body>
</opml>`;
    const downloadUrl = URL.createObjectURL(
      new File([opml], "youtube-subscriptions.opml", { type: "text/xml" })
    );

    // TODO find a way of triggering a download prompt without touching the DOM
    const el = document.createElement("a");
    el.href = downloadUrl;
    el.download = "youtube-subscriptions.opml";
    el.click();
    el.remove();
  }
</script>

{#if token !== null}
  <h1>Channels</h1>
  {#await fetchSubscribedChannels(token)}
    <p>Loading your YouTube subscriptions, this may take a moment.</p>
    {#if totalResults !== undefined}
      <p>
        Loaded {channels.length} (of {totalResults}) channels so far.
      </p>
    {/if}
  {:then _}
    <p>Loaded {channels.length} channels.</p>
    <p>
      You can choose which channels you'd like to export to your RSS reader
      below, and then export them as an OPML file.
    </p>
    <div style="display: flex; justify-content: center; flex-wrap: wrap;">
      <button on:click={includeAllChannels}>Select all channels</button>
      <button on:click={excludeAllChannels}>Unselect all channels</button>
    </div>
    <form>
      {#each channels as channel, index}
        <label>
          <input
            on:change={(e) => (includeChannel[index] = !includeChannel[index])}
            type="checkbox"
            id="channel-{index}"
            bind:checked={includeChannel[index]}
          />
          <img
            alt=""
            width="22"
            height="22"
            loading="lazy"
            src={channel.snippet.thumbnails.default.url}
          />
          {channel.snippet.title}
          <div class="spacer" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/{channel.snippet.resourceId
              .channelId}"
          >
            (channel)
          </a>
        </label>
      {/each}
      <button
        on:click={generateOpmlFile}
        type="button"
        style="display: block; margin: 16px auto 0;"
      >
        Generate OPML file
      </button>
    </form>
  {/await}
{/if}

<style>
  p {
    text-align: center;
  }
  form {
    max-width: 400px;
    margin: 0 auto;
  }
  label {
    display: flex;
    align-items: center;
    margin: 16px 0;
    cursor: pointer;
  }

  label > input {
    margin-right: 8px;
  }

  label > .spacer {
    flex-grow: 1;
    min-width: 8px;
  }

  img {
    margin-right: 8px;
  }

  button {
    margin: 8px;
    background-color: #fff;
    font-family: monospace;
    padding: 8px;
  }
</style>
