<script>
  export let clientId;
</script>

<style>
  img {
    display: block;
    margin: 0 auto;
  }
</style>

<h1>RSS Subscription Export</h1>
<h2>Export your YouTube subscriptions for your reader</h2>
<p>
  Until recently, you could export a list of your subscribed channels for your
  RSS reader in the YouTube subscription manager.
</p>
<p>Following a redesign though, this feature is currently unavailable.</p>
<p>
  As a substitute, I've made this little app! It integrates with YouTube to load
  your subscriptions, and then you can choose which channels you'd like to add
  to your RSS reader!
</p>
<p>Long live RSS!</p>
<h2>Usage</h2>
<ol>
  <li>
    Sign in with Google to allow the app to load your subscribed channels.
  </li>
  <li>Select which channels you'd like to add to your RSS reader.</li>
  <li>Export your selection as an OPML file to add to your reader.</li>
  <li style="padding-top: 16px;">
    <em>(Optional)</em>
    You can
    <a href="https://myaccount.google.com/permissions">
      revoke this app's access
    </a>
    to your Google account at any time
  </li>
</ol>
<a
  href="https://accounts.google.com/o/oauth2/v2/auth?client_id={clientId}&redirect_uri={window.location.origin}/callback&response_type=token&scope=https://www.googleapis.com/auth/youtube.readonly">
  <img
    style=" max-width: 191px;"
    alt="Sign in with Google button"
    src="/btn_google_signin_light_normal_web@2x.png" />
</a>
<img
  style=" max-width: 300px;"
  alt="Developed with YouTube logo"
  src="/developed-with-youtube-sentence-case-dark.png" />
<h2 id="privacy">Privacy</h2>
<p>
  This app integrates with YouTube to load your subscriptions, and as such it
  requires access to some information about your YouTube account.
</p>
<p>
  This app runs locally in your browser, and integrates with YouTube to load
  your subscriptions. No information is stored outside your browser.
</p>
<p>
  For convenience though, some data is stored locally in your browser when you
  use this app.
</p>
<ul>
  <li>
    The access token provided by YouTube is stored for the duration of your
    session, so it can be remembered between page refreshes.
  </li>
  <li>
    Any channels you choose not to export are stored in your browser's storage.
    This allows the app to remember which channels you don't want exported if
    you revisit it in future on the same browser.
  </li>
</ul>
