<script>
  let error;
  if (window.location.hash.startsWith("#error=")) {
    error = window.location.hash.substring(7);
  } else {
    const hashItems = window.location.hash
      .substring(1)
      .split("&")
      .reduce((acc, entry) => {
        const [key, value] = entry.split("=");
        acc[key] = value;
        return acc;
      }, {});
    sessionStorage.setItem("access_token", hashItems["access_token"]);
    sessionStorage.setItem(
      "access_token_expires",
      Date.now() + 1000 * Number.parseInt(hashItems["expires_in"], 10)
    );
    window.history.replaceState(null, "", `/channels`);
  }
</script>

<p style="color: red">Authentication with YouTube failed: {error}</p>
