<script>
  import Home from "./Home.svelte";
  import Callback from "./Callback.svelte";
  import Channels from "./Channels.svelte";
  export let clientId;

  let path = window.location.pathname;
  function updatePath() {
    path = window.location.pathname;
  }

  // hacky garbage
  const pushState = window.history.pushState;
  window.history.pushState = function() {
    pushState.apply(window.history, arguments);
    updatePath();
  };
</script>

<svelte:window on:pageshow={updatePath} on:popstate={updatePath} />

<main>
  {#if path == '/'}
    <Home {clientId} />
  {:else if path == '/callback'}
    <Callback />
  {:else if path == '/channels'}
    <Channels />
  {/if}

  <p style="text-align: center; margin-top: 32px;">
    <a
      href="/"
      on:click|preventDefault={() => window.history.pushState(null, '', '/')}>
      Home
    </a>
    &bull;
    <a href="https://github.com/nchlswhttkr/youtube-rss/">Source code</a>
  </p>
</main>
