import App from "./App.svelte";

const app = new App({
  target: document.body,
  props: {
    clientId:
      "597525368187-cj4cfpi3k1inana4c54i57eiie8pi1id.apps.googleusercontent.com",
  },
});

export default app;
